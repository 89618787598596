import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import searchIcon from '../../../../static/images/fill-search.svg';
import theme, { COLORS, BREAKPOINTS } from '../../../utils/theme';
import {
  Box,
  InputBase,
  styled,
  alpha,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  IconButton,
  Divider,
} from '@mui/material';
import Typography from '../../common/Typography';
import LinkWrapper from '../Link';
import Breadcrumb from '../Breadcrumb';
import parse from 'html-react-parser';
import { useLunr } from 'react-lunr';
import backIcon from '../../../../static/images/arrow-left.svg';
import { isMobile } from 'react-device-detect';
import { useFlags, useLDClient } from 'gatsby-plugin-launchdarkly';
import debounce from 'lodash.debounce';
import pagesData from '../../../utils/sideNavItems.json'
import routes from '../../../docs-config/src';
import { initialSession } from '../SecurityWrapper';
import { getPathName, isPathPrivate, getAccountName } from '../../../utils/helper';
import lock from '../../../../static/images/lock.svg';
import { AppContext } from '../../AppContextProvider';
import { countBy, orderBy } from 'lodash';
import { List } from 'react-virtualized';
import { useMediaQuery } from '@mui/material';
import ChatButtonWrapper from '../AskAIButtonWrapper';
import logo from '../../../../static/images/ai.svg';
import { CustomBaseSettings } from '../AskAI';
import DOMPurify from 'dompurify';
import globalMessages from '../../../utils/messages/en/globalMessages';
import { getcookieurl } from '../../docs/StudioLinks';

const useStyles = {
  menuListStyle: {
    '& .MuiList-root.MuiMenu-list': { padding: '2px 4px' },
    '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      borderRadius: '0px 0px 4px 4px',
      marginLeft: '4px',
      boxShadow: theme.shadows[12],
    },
  },
  arrowBackIconStyling: {
    width: '20px',
    height: '20px',
  },
  arrowBack: {
    padding: '0px',
  },
  menuItems: {
    paddingTop: '10px',
    paddingLeft: '10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    borderRadius: '4px',
  },
  listItemWrapper: {
    padding: '0px 2px 0px',
  },
  scrollableResults: {
    overflowY: 'auto',
  },
  searchBoxStyle: {
    padding: '0px 16px 0px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      boxShadow: 'none',
      padding: '0px',
      height: 'unset',
    },
  },
  breadCrumbStyle: {
    marginTop: '2px',
  },
  contentStyle: {
    marginTop: '4px',
  },
  titleStyle: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    fontFamily: 'Cosmica',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  styledBox: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'start', 
    alignContent: 'center', 
    columnGap: '10px', 
    minHeight: '37px', 
    paddingBottom: '10px',
  },
  searchFocusStyle: {
    border: '1px solid',
  },
  searchBoxDefault: {
    boxShadow: 'none',
    padding: '0px 16px 0px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      padding: '0px',
      height: '26px',
    },
  },
  noResultsBox: {
    padding: '16px 16px 16px 20px',
  },
  NoResultsStyledPaper: {
    boxShadow: 7,
    width: 622,
    marginLeft: '-1px',
    marginTop: '10px',
    border: '1px solid rgba(0, 0, 0, 0.18)',
    [BREAKPOINTS.DESKTOP]: {
      boxShadow: 'none',
      width: '210%',
      marginLeft: '0px',
    },
    [BREAKPOINTS.ANDROID]: {
      boxShadow: 'none',
      width: '250px',
      marginLeft: '0px',
    },
    [BREAKPOINTS.MEDIA_MIN_375]: {
      boxShadow: 'none',
      width: '305px',
      marginLeft: '0px',
    },
    [BREAKPOINTS.MEDIA_MIN_420]: {
      boxShadow: 'none',
      width: '350px',
      marginLeft: '0px',
    },
    [BREAKPOINTS.MEDIA_MIN_532]: {
      boxShadow: 'none',
      width: '460px',
      marginLeft: '0px',
    },
    [BREAKPOINTS.MEDIA_MIN_768]: {
      boxShadow: 'none',
      width: '700px',
      marginLeft: '0px',
    },
    [BREAKPOINTS.MEDIA_MIN_1200]: {
      boxShadow: 'none',
      width: '419px',
      marginLeft: '0px',
    },
  },
  noResutsTypography: { 
    fontSize: '14px', 
    color: COLORS.TEXT_SECONDARY, 
    fontWeight: 500, 
    fontFamily: 'Inter'
  },
  chipStyle: {
    background: '#565CC429',
    borderRadius: '4px',
    height: '24px',
    width: '97px',
    marginLeft: '10px',
  },
  chipTypography: {
    fontFamily: 'cosmica', 
    fontSize: '10px', 
    color: COLORS.SECONDARY_MIDNIGHT, 
    paddingLeft: '5px', 
    paddingTop:'1px'
  },
  imageStyle: {
   paddingLeft: '10px',
   paddingBottom: '1px',
   height: '16px',
   width: '16px',
  },
};
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  width: '489px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '234px',
  },
  [BREAKPOINTS.MEDIA_MIN_1400]: {
    width: '620px',
  },
  [BREAKPOINTS.MEDIA_MIN_1200]: {
    width: '420px',
  },
  [BREAKPOINTS.MOBILE]: {
    width: 'calc(100% - 8px)',
    height: '26px',
    marginLeft: theme.spacing(2),
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  paddingLeft: '16px',
  paddingTop: '1px',
  height: '19px',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  'input': {
    '&::placeholder': {
      color: COLORS.TEXT_MEDIUM_EMPHASIS,
      opacity: 0.96,
      fontSize: '14px',
      fontFamily: 'Inter',
      lineHeight:'19.6px',
      letterSpacing: '0.14px'
    }
  },
  '& .MuiInputBase-input': {
    paddingLeft: '45px',
    height: '19px',
    transition: theme.transitions.create('width'),
    width: '100%',
    '&:focus::-webkit-input-placeholder': {
      opacity: 0,
    },
  },
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[14],
  width: 622,
  [BREAKPOINTS.DESKTOP]: {
    width: '60%',
  },
  [BREAKPOINTS.ANDROID]: {
    width: '250px',
  },
  [BREAKPOINTS.MEDIA_MIN_375]: {
    width: '305px',
  },
  [BREAKPOINTS.MEDIA_MIN_420]: {
    width: '350px',
  },
  [BREAKPOINTS.MEDIA_MIN_532]: {
    width: '460px',
  },
  [BREAKPOINTS.MEDIA_MIN_768]: {
    width: '700px',
  },
  [BREAKPOINTS.MEDIA_MIN_1200]: {
    width: '419px',
  },
  [BREAKPOINTS.MEDIA_MAX_1199]: {
    width: '319px',
  },
  marginLeft: '-1px',
  marginTop: '10px',
  border: '1px solid rgba(0, 0, 0, 0.18)',
}));
const DescriptionTypography = styled(Typography)(() => ({
  color: COLORS.TEXT_BODY,
  [BREAKPOINTS.MOBILE]: {
    overflow: 'auto',
    textOverflow: 'unset',
    whiteSpace: 'unset',
  },
}));
interface SearchItem {
  id: string;
  path: string;
  title: string;
  desc: string;
  links: any;
  content?: string;
  restricted?: boolean;
}
const getResults = (searchResults, results) => {
  const duplicateMap = {};
  results.forEach((item) => {
    duplicateMap[item.path] = item;
  });
  searchResults.forEach((element) => {
    if (!duplicateMap[element.path]) {
      results.push(element);
      duplicateMap[element.path] = element;
    }
  });
  return results;
};

const buildDocsSearchResults = (
  headerResults,
  pageResults,
  apiHeaderResults,
  apiContentResults,
  includeAPIResults,
) => {
  let resultList: SearchItem[] = [];
  if (headerResults.length > 0) {
    resultList = getResults(headerResults, resultList);
  }
  if (pageResults.length > 0) {
    resultList = getResults(pageResults, resultList);
  }
  if (includeAPIResults) {
    if (apiHeaderResults.length > 0) {
      resultList = getResults(apiHeaderResults, resultList);
    }
    if (apiContentResults.length > 0) {
      resultList = getResults(apiContentResults, resultList);
    }
  }
  return resultList;
};
const buildApiSearchResults = (
  headerResults,
  pageResults,
  apiHeaderResults,
  apiContentResults,
  includeAPIResults,
) => {
  let resultList: SearchItem[] = [];
  if (includeAPIResults) {
    if (apiHeaderResults.length > 0) {
      resultList = getResults(apiHeaderResults, resultList);
    }
    if (apiContentResults.length > 0) {
      resultList = getResults(apiContentResults, resultList);
    }
  }
  if (headerResults.length > 0) {
    resultList = getResults(headerResults, resultList);
  }
  if (pageResults.length > 0) {
    resultList = getResults(pageResults, resultList);
  }
  return resultList;
};
// To be Removed once mobile site is ready
const useAPISearchResults = (oldSiteEnabled) => {
  return !(isMobile && oldSiteEnabled);
};
interface SearchFieldProps {
  pageType: 'docs' | 'api' | 'apireference' | 'sdk-ref';
  handleClick?: () => void;
  isSearchOpen?: boolean;
  baseSettings: CustomBaseSettings;
}
const addSearchTermToDataLayer = (value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'searchTermsSubmission',
      searchTerm: value,
    });
  }
  return '';
};

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { pageType } = props;
  const data = useStaticQuery(graphql`
    query SearchQuery {
      allLocalSearchPages {
        nodes {
          index
          store
        }
      }
      allLocalSearchHeader {
        nodes {
          headerIndex: index
          headerStore: store
        }
      }
      allLocalSearchApiContent {
        nodes {
          apiContentIndex: index
          apiContentStore: store
        }
      }
      allLocalSearchApiHeader {
        nodes {
          apiHeaderIndex: index
          apiHeaderStore: store
        }
      }
    }
  `);
  const { index, store } = data.allLocalSearchPages.nodes[0];
  const { headerIndex, headerStore } = data.allLocalSearchHeader.nodes[0];
  const { apiContentIndex, apiContentStore } =
    data.allLocalSearchApiContent.nodes[0];
  const { apiHeaderIndex, apiHeaderStore } =
    data.allLocalSearchApiHeader.nodes[0];

  const [query, setQuery] = useState('');
  const [val, setValue] = React.useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFocused, setFocused] = useState(false);
  const open = Boolean(anchorEl);
  const flags = useFlags(); // To be Removed once mobile site is ready
  const headerResults = useLunr(query, headerIndex, headerStore);
  const pageResults = useLunr(query, index, store);
  const apiHeaderResults = useLunr(query, apiHeaderIndex, apiHeaderStore);
  const apiContentResults = useLunr(query, apiContentIndex, apiContentStore);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const setAuthenticated = React.useContext(AppContext).setIsAuthenticated;
  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const isMediumScreen = useMediaQuery('(min-width:768px) and (max-width:1023px)');
  const isMobile = useMediaQuery('(min-width: 532px) and (max-width: 767px)');
  const baseurl = getcookieurl(globalMessages.applicationUrl);
  const ldClient = useLDClient();

  React.useEffect(() => {
    if (baseurl !== '' && baseurl !== routes.preLogin) {
      ldClient
        ?.identify({
          key: getAccountName(baseurl),
          name: getAccountName(baseurl),
          email: baseurl,
        })
    }
  }, [ldClient]);

  let itemSize = 90;
  let snippetLength = 35;
  let listWidth = 600;
  if (isLargeScreen) {
    snippetLength = 60;
    listWidth = 618;
  } else if (isMediumScreen) {
    snippetLength = 45;
    listWidth = 696;
  } else if (isMobile) {
    snippetLength = 28;
    listWidth = 485;
  }
  let results: SearchItem[] = [];
  if (pageType === 'api') {
    results = buildApiSearchResults(
      headerResults,
      pageResults,
      apiHeaderResults,
      apiContentResults,
      useAPISearchResults(flags.enableApiReference),
    );
  } else {
    results = buildDocsSearchResults(
      headerResults,
      pageResults,
      apiHeaderResults,
      apiContentResults,
      useAPISearchResults(flags.enableApiReference),
    );
  }
  const escapeSpecialCharacters = (text) => {
    const specialCharacters = /[~`!@#$%^&*()_+={}\[\]:;"'<>,.?\/\\|]/g;
    return text.replace(specialCharacters, '');
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let sanitizedValue = DOMPurify.sanitize(event.target.value);
    sanitizedValue = escapeSpecialCharacters(sanitizedValue);
    setValue(sanitizedValue);
    setQuery(
      sanitizedValue +
        ' | ' +
        '*' +
        sanitizedValue +
        '*' +
        ' | ' +
        '*' +
        sanitizedValue +
        ' | ' +
        sanitizedValue +
        '*',
    );
    if (sanitizedValue && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleKeyup = (event) => {
    setFocused(false);
    if (val && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
    if (props.handleClick) {
      props.handleClick();
    }
  };
  const handleOnfocus = (event) => {
    setFocused(true);
    if (val && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const getSnippet = (text, query, snippetLength) => {
    if (!text || !query) return '';
    const cleanText = (text) => {
      const pattern = /({%.*?%}|<[^>]*>)/g;
      return text.replace(pattern, '');
    };
    const cleanedText = cleanText(text);
    const regex = new RegExp(`(?<=^|\\s).{0,${snippetLength}}${query}.{0,${snippetLength}}(?=\\s|$)`, 'gi');
    const match = regex.exec(cleanedText);
    if (match) return match[0] + '...';
    return cleanedText.substring(0, snippetLength) + '...';
  };

  const highLight = (content) => {
    if (!content) {
      return '';
    }
    const options = {
      htmlparser2: {
        lowerCaseTags: false,
      },
    };
    const regex = new RegExp(val, 'gi');
    const highlightedContent = content.replace(regex, (str) => `<b>${str}</b>`);
    const sanitizedContent = DOMPurify.sanitize(highlightedContent);
    return parse(sanitizedContent, options);
  };
  const updateSearchTerm = (event) => {
    const value = event.target.value;
    if (flags && flags.enableDocAnalyticsEvents === true) {
      addSearchTermToDataLayer(value);
    }
  };
  const handleClickAway = () => {
    if (!isFocused) {
      setAnchorEl(null);
    }
  };
  const debounceOnChange = React.useCallback(
    debounce(updateSearchTerm, 1000),
    [],
  );

  React.useEffect(() => {
    const checkSession = async () => {
      const intialValue = await initialSession();
      setIsAuthenticated(intialValue);
      setAuthenticated(intialValue);
      return intialValue;
    };
    checkSession();
  },[]);

  let filteredResults = results.map(item => {
    const isPublic = isPathPrivate(getPathName(item.path, routes), pagesData) === 'public';
    if (!isPublic && !isAuthenticated) {
      return { ...item, restricted: true };
    }
    return item;
  });
  filteredResults = filteredResults.sort((a, b) => {
    const aHasTerm = a.content?.toLowerCase().includes(val.toLowerCase()) ? 1 : 0;
    const bHasTerm = b.content?.toLowerCase().includes(val.toLowerCase()) ? 1 : 0;
    return bHasTerm - aHasTerm;
  });
  const excludedTerms = ['record', 'management', 'detect'];
  const titleCounts = countBy(filteredResults, item => item.links[0].title);
  const topTitles = orderBy(Object.entries(titleCounts), ([title, count]) => count, 'desc')
    .slice(0, 5)
    .map(([title]) => title)
    .filter(title => !excludedTerms.includes(title));
  const uniqueTopTitles = [...new Set(topTitles)];
  const topResults = uniqueTopTitles
    .map(title => filteredResults.find(item => item.links[0].title === title))
    .filter((item): item is SearchItem => item !== undefined); 
  filteredResults = [...topResults, ...filteredResults.filter(item => !topResults.includes(item))];

  const removeDuplicates = (results) => {
    const seenLinks = new Set();
    return results.filter(result => {
      const href = result.links[0].href;
      if (!seenLinks.has(href)) {
        seenLinks.add(href);
        return true;
      }
      return false;
    });
  };

  const maxVisibleItems = 5;
  const calculateListHeight = (itemCount) => {
    const totalHeight = itemCount * itemSize;
    const maxHeight = maxVisibleItems * itemSize;
    return Math.min(totalHeight, maxHeight);
  };
  const listHeight = calculateListHeight(filteredResults.length);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const rowRenderer = ({ index, style }) => {
    const item = filteredResults[index];
    return (
      <div style={style} key={item.path}>
        <Box
          onClick={() => handleMenuItemClick()}
          sx={useStyles.menuItems}
        >
          <LinkWrapper href={item.path}>
            <Box sx={useStyles.styledBox}>
              <Typography
                type="DESKTOP_BODY_PARAGRAPH_MEDIUM"
                sx={useStyles.titleStyle}
              >
              <div style={{ display: 'flex', alignItems: 'center'}}>
                {capitalizeFirstLetter(item.links[0].title)}
                {item.restricted && !isAuthenticated &&
                  <img src={lock} style={useStyles.imageStyle}/>
                }
              </div>
              </Typography>
                  <Box sx={useStyles.contentStyle}>
                    <DescriptionTypography variant="body2" noWrap>
                      {highLight(getSnippet(item.content || item.desc, val, snippetLength))}
                    </DescriptionTypography>
                  </Box>
                  <Box sx={useStyles.breadCrumbStyle}>
                    <Breadcrumb
                      links={item.links}
                      onClick={handleMenuItemClick}
                    />
                  </Box>
                </Box>
              </LinkWrapper>
              {index !== filteredResults.length - 1 && (
                <Divider orientation="horizontal" flexItem />
              )}
            </Box>
        </div>
    );
  };
  return (
    <Box sx={open ? useStyles.searchBoxStyle : useStyles.searchBoxDefault}>
      {props.isSearchOpen && 
        <IconButton
          disableRipple
          sx={useStyles.arrowBack}
          aria-label="arrow back icon"
          onClick={props.handleClick}
        >
          <img
            src={backIcon}
            style={useStyles.arrowBackIconStyling}
            alt="search back icon"
          />
        </IconButton>
      }
      <Search sx={isFocused ? useStyles.searchFocusStyle : {}}>
        <SearchIconWrapper>
          <img src={searchIcon} alt="search" width={18} height={18} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search docs"
          inputProps={{ 'aria-label': 'search' }}
          value={val}
          onBlur={handleKeyup}
          onChange={(e) => {
            debounceOnChange(e);
            handleChange(e);
          }}
          onFocus={handleOnfocus}
        />
        {(flags && flags.askAiDocs) && (isAuthenticated) &&
          <>
            <div style={{ paddingRight: '24px'}}>
              <ChatButtonWrapper baseSettings={props.baseSettings} />
            </div>
          </>
         } 
      </Search>
      {filteredResults.length > 0 && 
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            transition
            style={{ zIndex: '111' }}
            disablePortal={true}
          >
            {({ TransitionProps }) => 
              <Fade {...TransitionProps} timeout={0}>
                <StyledPaper elevation={8}>
                  <Box sx={{ ...useStyles.listItemWrapper, ...useStyles.scrollableResults }}>
                  <List
                    width={listWidth}
                    height={listHeight}
                    rowCount={filteredResults.length}
                    rowHeight={itemSize}
                    rowRenderer={rowRenderer}
                  />
                  </Box>
                </StyledPaper>
              </Fade>
            }
          </Popper>
        </ClickAwayListener>
      }
      {filteredResults.length === 0 && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            transition
            style={{ zIndex: '111' }}
            disablePortal={true}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={0}>
                <Paper elevation={8} sx={useStyles.NoResultsStyledPaper}>
                  <Box sx={useStyles.noResultsBox}>
                    <Typography sx={useStyles.noResutsTypography}>No results</Typography>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>)}
    </Box>
  );
};
export default SearchField;
