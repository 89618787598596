import React from 'react';
import routes from "../../../docs-config/src";
import globalMessages from "../../../utils/messages/en/globalMessages";
import { isValidUrl } from "../../common/SecurityWrapper";
import { COLORS } from '../../../utils/theme';
import LinkWrapper from '../../common/Link';

export const getcookieurl = (cookiename) => {
  if (typeof document !== 'undefined') {
    const cookiestring = document.cookie;
    const cookiearray = cookiestring.split(';');
    for (const element of cookiearray) {
      if (element.trim().match('^' + cookiename + '=')) {
        let href = element.replace(`${cookiename}=`, '').trim();
        if (href && isValidUrl(href)) {
          if (href.endsWith('/')) {
            href = href.slice(0, -1);
          }
          return href;
        }
      }
    }
  }
  return routes.preLogin;
}
const handleStudioLinks = (route) => {
  const url = getcookieurl(globalMessages.applicationUrl) + route;
  window.open(url);
};

const OpenStudioLink = ({ route, textToDisplay }) => {
  const handleClick = () => {
    handleStudioLinks(route);
  };
  const baseurl = getcookieurl(globalMessages.applicationUrl);
  if (baseurl === routes.preLogin) {
    return (
      <LinkWrapper href={routes.preLogin} maintainPrevPath={true} style={{ color: COLORS.LINK_COLOR }}>
        {textToDisplay}
      </LinkWrapper>
    );
  } else {
    return (
      <span style={{ cursor: 'pointer', color: COLORS.LINK_COLOR }} onClick={handleClick}>
        {textToDisplay}
      </span>
    );
  }
};

export default OpenStudioLink;