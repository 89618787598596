type ButtonType = 'PILL' | 'RECTANGLE_SHORTCUT' | 'ICON';

const globalMessages = {
  documentation: 'Documentation',
  apiReference: 'API Reference',
  signIn: 'Sign in',
  copyRight: 'Skyflow, Inc. All rights reserved.',
  copyRightSymbol: '©',
  termsAndCondition: 'Terms and Conditions',
  cookiePolicy: 'Cookie Policy',
  skyFlowSite: 'Skyflow.com',
  docsPage: 'DOCS',
  apiReferencePage: 'API',
  start: 'START',
  rightNavHeader: 'In this article',
  homePage: 'home',
  sdKPage: 'SDKs',
  demoAndSampleAppPage: 'Demos and Sample Apps',
  apiReferencePages: 'API Reference',
  signInNotice: 'This page is only available to signed-in Skyflow users.',
  Trial: 'Trial',
  Production: 'Production',
  privacyPolicy: 'Privacy Policy',
  openStudio: 'Go to Studio',
  applicationUrl: 'application-url',
  domainName: 'skyflow',
  Sandbox: 'Sandbox',
  sandoxDomain:'skyflow-preview',
  applicationURL:'application-url',
  getAccess: 'Get access',
  getStarted: 'Get started with Skyflow',
  explore: 'Explore what Skyflow can do',
  authenticate: 'Authenticate',
  dataApi: 'Data API reference',
  note: 'Sign in or create an account to access this page.',
  title: 'Explore some of our popular public docs:',
  version: '1.0.0',
  coralogixDomain: 'US2',
  application: 'skyflow-docs',
  question1: 'What is PII Data Privacy Vault?',
  question2: 'How to collect sensitive data from the client-side?',
  question3: 'How to share limited access to a vault?',
  botAvatarSrcUrl: 'https://storage.googleapis.com/organization-image-assets/skyflow-botAvatarSrcUrl-1720476247709.png',
  chatSubjectName: 'Skyflow',
  chatButtonType: 'PILL' as ButtonType,
  chatButtonText: 'Ask-AI (beta)',
  organizationDisplayName: 'Inkeep',
  botName: "AI (beta)",
  introMessage: "Hi, I'm Ask-AI assistant trained on Skyflow documentation.",
  guidance: "The AI is uncertain or lacks complete information due to being in beta.",
  disclaimerLabel: "Beta Notice", 
  disclaimerTooltip: "This AI assistant is in beta and may have limitations."       
};

export default globalMessages;
